import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'next-i18next';
import { useMediaQuery, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePlausible } from 'next-plausible';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { cookieKey } from '@cr/common/src/config/constants';
import { isValidLocale } from '@cr/common/src/validation';
import {
  GET_TRANSACTIONS,
  GET_USER,
  GET_WALLET,
  GET_WALLETS_LITE,
} from '../../apollo/typeDefs';
import { ROUTES, getPublicPageStatus } from '../../utils/AppRoutes';
import useLocalStorage from './useLocalStorage';
import SpinnerLight from '../../media/spinnerLight.svg';
import IconButton from '../IconButton';
import CloseIcon from '../../media/icons/ic-actions-close-simple.svg';

const USER_CALCULATING = gql`
  subscription userCalculating {
    userCalculating
  }
`;

const REFETCH_TRANSACTIONS = gql`
  subscription refetchTransactions {
    refetchTransactions
  }
`;

const REFETCH_WALLET = gql`
  subscription refetchWallet {
    refetchWallet
  }
`;

const REPORT_DOWNLOAD_URL = gql`
  subscription reportDownloadUrl {
    reportDownloadUrl
  }
`;

const { publicRuntimeConfig } = getConfig();

export const UsersContext = createContext({});
export const UsersProvider = ({ children }) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const plausible = usePlausible();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [afterLogout, setAfterLogout] = useState(false);
  const [isLightVariant, setIsLightVariant] = useState(true);
  const [taxStatsErrorSnack, setTaxStatsErrorSnack] = useState(null);

  const userQuery = useQuery(GET_USER);
  const apolloClient = useApolloClient();
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies([cookieKey]);
  const [prevUserToken, setPrevUserToken] = useLocalStorage(
    'prevUserToken',
    null
  );

  const [refetchTransactions] = useLazyQuery(GET_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
  });
  const [refetchWallet] = useLazyQuery(GET_WALLET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [refetchWallets, { data: walletsData }] =
    useLazyQuery(GET_WALLETS_LITE);

  async function login({
    token,
    afterLoginDestination = ROUTES.DASHBOARD,
    impersonate = false,
  }) {
    if (impersonate) {
      const currentAdminToken = cookies[cookieKey];
      setPrevUserToken(currentAdminToken);
    } else if (prevUserToken) setPrevUserToken(null);
    await cleanCache();

    setCookie(cookieKey, token, customCookieOptions());
    const {
      data: { user: newUser },
    } = await userQuery.refetch();

    // forward to the dashboard
    if (newUser) {
      setAfterLogout(false);
      apolloClient.wsClient?.connect();
      if (afterLoginDestination)
        await router.push(afterLoginDestination, null, {
          locale:
            newUser.locale && isValidLocale(newUser.locale)
              ? newUser.locale
              : 'en',
        });
    }
  }

  async function goBackToPrevUser() {
    if (!prevUserToken) return;
    await login({ token: prevUserToken });
  }

  async function logout(forwardLandingPage = true) {
    setAfterLogout(true);
    if (forwardLandingPage) await router.push('/');
    setUser(null);
    removeCookie(cookieKey, customCookieOptions());
    await cleanCache();
    console.log('logout');
  }

  async function cleanCache() {
    try {
      apolloClient.wsClient?.close(true);
      await apolloClient.resetStore();
    } catch (e) {
      // some "unauthorized" errors are thrown because of the missing user key at this point...
    }
  }

  useEffect(() => {
    if (userQuery.loading) {
      if (!userLoading) setUserLoading(true);
    } else {
      if (userLoading) setUserLoading(false);

      const newUser = userQuery?.data?.user;
      if (newUser && !afterLogout) setUser(newUser);
      else if (
        newUser === null &&
        !getPublicPageStatus(router.pathname) &&
        !router.pathname?.includes('sign-in?back=')
      )
        router.push(`/sign-in?back=${encodeURI(router.pathname)}`);
    }
  }, [userQuery]);

  useEffect(() => {
    if (!user || typeof window === 'undefined') return; // no ssr

    /* eslint-disable-next-line */
    if (
      window.Intercom &&
      publicRuntimeConfig.stage !== 'development' &&
      user.chatToken &&
      !prevUserToken
    ) {
      /* eslint-disable-next-line */
      window.Intercom('boot', {
        app_id: publicRuntimeConfig.intercomAppId,
        user_id: user._id,
        user_hash: user.chatToken,
        email: user.email.current,
        name: user.address?.firstname,
      });
    }

    // log opened session in plausible for better analysis
    if (!user.isAdmin && !user.isDemo) plausible('Session');

    // load all user wallets directly after login, we need this to check is any of them is currently syncing
    refetchWallets();

    const subs = [
      apolloClient
        .subscribe({ query: USER_CALCULATING })
        .subscribe(({ data }) => {
          const calcCode = data.userCalculating; // -2 over 150 asset convs, -1 error, 0 false, 1 true
          setTimeout(() => {
            if (calcCode === 1)
              apolloClient.cache.modify({
                id: apolloClient.cache.identify(user),
                fields: { calculating: () => true },
              });
            else
              apolloClient.refetchQueries({
                include: ['getUser', 'wallet', 'wallets', 'taxYears'],
              });

            if (calcCode < 0 && !taxStatsErrorSnack)
              setTaxStatsErrorSnack(
                enqueueSnackbar(
                  calcCode === -2
                    ? t('taxStats-asset-error')
                    : t('taxStats-error'),
                  {
                    persist: true,
                    variant: 'error',
                    action: (snackbarId) => (
                      <IconButton
                        size="small"
                        color="info"
                        variant="contained"
                        onClick={() => closeSnackbar(snackbarId)}
                      >
                        <CloseIcon
                          style={{ height: 20, width: 20, stroke: 'white' }}
                        />
                      </IconButton>
                    ),
                  }
                )
              );
          }, 200);
        }),
      apolloClient
        .subscribe({ query: REFETCH_TRANSACTIONS })
        .subscribe(async ({ data }) => {
          const txIds = data.refetchTransactions;
          if (txIds.length < 1) {
            setTimeout(() => {
              apolloClient.refetchQueries({ include: ['transactions'] });
            }, 200);
          } else await refetchTransactions({ variables: { _ids: txIds } });
        }),
      apolloClient
        .subscribe({ query: REFETCH_WALLET })
        .subscribe(async ({ data }) => {
          const walletId = data.refetchWallet;
          await refetchWallet({ variables: { _id: walletId } });
        }),
      apolloClient
        .subscribe({ query: REPORT_DOWNLOAD_URL })
        .subscribe(({ data }) => {
          apolloClient.refetchQueries({ include: ['taxYears'] });

          const response = data.reportDownloadUrl;
          if (response === 'error')
            enqueueSnackbar('Unknown error while PDF generation.', {
              variant: 'error',
            });
          else
            enqueueSnackbar(t('Dein Download steht bereit!'), {
              persist: true,
              variant: 'info',
              action: (snackbarId) => (
                <>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{
                      height: 33.5,
                      marginRight: 4,
                      marginTop: isMobile ? 8 : 0,
                    }}
                    onClick={() => {
                      closeSnackbar(snackbarId);

                      const link = document.createElement('a');
                      link.href = data.reportDownloadUrl;
                      link.target = '_blank';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);

                      // todo
                      // send invitation for trustpilot review via email
                      // if (window.tp)
                      //   window.tp('createInvitation', {
                      //     recipientEmail: user.email.current,
                      //     recipientName: user.address.firstname,
                      //     referenceId: user._id,
                      //     source: 'InvitationScript',
                      //   });
                    }}
                  >
                    {t('Herunterladen')}
                  </Button>
                  <IconButton
                    size="small"
                    color="info"
                    variant="contained"
                    onClick={() => closeSnackbar(snackbarId)}
                  >
                    <CloseIcon
                      style={{ height: 20, width: 20, stroke: 'white' }}
                    />
                  </IconButton>
                </>
              ),
            });
        }),
    ];
    return () => subs.forEach((sub) => sub.unsubscribe());
  }, [user?._id]);

  // hide intercom chat on mobile devices
  useEffect(() => {
    /* eslint-disable-next-line */
    if (window.Intercom) {
      /* eslint-disable-next-line */
      window.Intercom('update', {
        hide_default_launcher: Boolean(user) && isMobile,
      });
    }
  }, [user, isMobile]);

  // calculations snackbar
  const [runningJob, setRunningJob] = useState(null);
  useEffect(() => {
    let job = null;
    if (user) {
      if (walletsData?.wallets?.some((w) => w.api?.state === 'SYNCING'))
        job = 'job-syncing';
      else if (user.calculating) job = 'job-processing';
    }
    if (job !== runningJob) setRunningJob(job);
  }, [user, walletsData]);

  const [jobSnackbar, setJobSnackbar] = useState(null);
  useEffect(() => {
    if (!enqueueSnackbar) return;
    if (!runningJob && jobSnackbar) {
      closeSnackbar(jobSnackbar);
      setJobSnackbar(null);
    } else if (runningJob) {
      const newSnackbar = enqueueSnackbar(t(runningJob), {
        persist: true,
        variant: 'info',
        action: (snackbarId) => (
          <>
            <SpinnerLight
              style={{ height: 24, width: 24, margin: -5, marginRight: 5 }}
            />
            <IconButton
              size="small"
              color="info"
              variant="contained"
              onClick={() => closeSnackbar(snackbarId)}
            >
              <CloseIcon style={{ height: 20, width: 20, stroke: 'white' }} />
            </IconButton>
          </>
        ),
      });
      if (jobSnackbar) closeSnackbar(jobSnackbar);
      setJobSnackbar(newSnackbar);
    }
  }, [enqueueSnackbar, runningJob]);

  return (
    <UsersContext.Provider
      value={{
        isImpersonate: !!prevUserToken,
        user,
        loading: userLoading,
        refetch: userQuery.refetch,
        logout,
        login,
        goBackToPrevUser,
        isLightVariant,
        setIsLightVariant,
        runningJob,
        setRunningJob,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

function customCookieOptions() {
  const uriParts = window.location.hostname.split('.');
  if (uriParts.length > 2) uriParts.shift();

  return {
    path: '/',
    sameSite: 'strict',
    secure: window.location.protocol.includes('https'),
    domain: uriParts.join('.'),
    httpOnly: false,
  };
}
