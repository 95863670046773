import { useQuery } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';

import { GET_WALLETS_LITE } from '../../apollo/typeDefs';

const TransactionClearing = () => {
  const apolloClient = useApolloClient();
  const [syncingWIds, setSyncingWIds] = useState([]);
  const { data } = useQuery(GET_WALLETS_LITE);

  useEffect(() => {
    if (!data?.wallets) return;

    const newSyncingWIds = data.wallets
      .filter((w) => w.api?.state === 'SYNCING')
      .map((w) => w._id);
    if (
      newSyncingWIds.some((id) => !syncingWIds.includes(id)) ||
      syncingWIds.some((id) => !newSyncingWIds.includes(id))
    ) {
      if (newSyncingWIds.length < syncingWIds.length)
        apolloClient.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'transactions',
        });
      setSyncingWIds(newSyncingWIds);
    }
  }, [data]);

  return null;
};

export default TransactionClearing;
