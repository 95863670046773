import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import fetch from 'isomorphic-unfetch';
import isEqual from 'lodash.isequal';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { cookieKey } from '@cr/common/src/config/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const { publicRuntimeConfig } = getConfig();

function createApolloClient(ctx) {
  let link;
  let wsClient;

  if (process.browser) {
    wsClient = new SubscriptionClient(publicRuntimeConfig.wsUrl, {
      lazy: true,
      reconnect: true,
      reconnectionAttempts: 60,
      inactivityTimeout: 60 * 1000,
      connectionParams: () => ({
        cookie: document.cookie,
      }),
    });

    link = from([
      // As any link onError receives { operation, forward }
      // More information about error handling can be found here: https://www.apollographql.com/docs/react/data/error-handling/
      // e.g. If you want to retry an graphQLError you can return forward(operation) or create a RetryLink for network errors
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          console.error('graphQLErrors occured:');
          console.dir(graphQLErrors, { depth: null });
        }
        if (networkError) {
          console.error('An network error occured:');
          console.dir(networkError, { depth: null });
        }
      }),
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        new WebSocketLink(wsClient),
        new CreateUploadLink({
          uri: publicRuntimeConfig.graphUrl,
          credentials: 'include',
          fetch,
          ...(ctx ? { headers: { cookie: ctx.req.headers.cookie } } : {}),
        })
      ),
    ]);
  }

  const apolloClient = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Wallet: {
          fields: {
            api: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
      },
    }),
  });

  if (wsClient) {
    apolloClient.wsClient = wsClient;
    if (document.cookie.includes(cookieKey)) wsClient.connect();
  }
  return apolloClient;
}

let apolloClient;
export function initializeApollo({ initialState, ctx }) {
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore(
      merge(initialState, existingCache, {
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) =>
            sourceArray.every((s) => !isEqual(d, s))
          ),
        ],
      })
    );
  }

  if (typeof window === 'undefined') return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo({ initialState: state }), [state]);
}
