import { gql } from '@apollo/client';

export const LIST_TRANSACTION_FRAGMENT = gql`
  fragment ListTransaction on Transaction {
    _id
    visible
    tradeDate
    type
    commentaries

    wallet {
      _id
      name
    }

    hasWarnings
    meta {
      totalProfit
    }

    amounts {
      amount
      feeAmount
      asset {
        symbol
        type
        _id
        cmcId
        containingPoolPairs {
          asset {
            _id
            cmcId
            symbol
            type
          }
          factor
        }
      }
    }

    transfer {
      pred {
        _id
        amounts {
          _id
          amount
          feeAmount
          asset {
            symbol
            type
            _id
            cmcId
            containingPoolPairs {
              asset {
                _id
                cmcId
                symbol
                type
              }
              factor
            }
          }
        }
        meta {
          totalProfit
        }
        wallet {
          _id
          name
        }
      }

      succ {
        _id
        amounts {
          _id
          amount
          feeAmount
          asset {
            symbol
            type
            _id
            cmcId
            containingPoolPairs {
              asset {
                _id
                cmcId
                symbol
                type
              }
              factor
            }
          }
        }
        meta {
          totalProfit
        }
        wallet {
          _id
          name
        }
      }
    }
  }
`;

export const DETAILS_TRANSACTION_FRAGMENT = gql`
  fragment DetailsTransaction on Transaction {
    _id
    visible
    tradeDate
    commentaries
    isMarkedAsX
    type
    wallet {
      _id
      name
      exchange
    }
    source {
      type
    }
    explorerLink

    hasWarnings
    meta {
      warnings {
        _id
        type
        data
        amountId
        noticed
      }

      totalProfit
      gains {
        asset {
          symbol
          type
          _id
          cmcId
        }
        amount
        buyCoinPriceInFiat
        sellCoinPriceInFiat
        gainInFiat
        holdDays
      }
    }

    amounts {
      _id
      amount
      feeAmount
      asset {
        symbol
        type
        _id
        cmcId
        containingPoolPairs {
          asset {
            _id
            cmcId
            symbol
            type
          }
          factor
        }
      }
      coinPriceInFiat
      unknownPrice
      lockedPrice
    }

    transfer {
      pred {
        _id
        amounts {
          _id
          amount
          feeAmount
          asset {
            symbol
            type
            _id
            cmcId
            containingPoolPairs {
              asset {
                _id
                cmcId
                symbol
                type
              }
              factor
            }
          }
        }
        wallet {
          _id
          name
          exchange
        }
        meta {
          gains {
            asset {
              symbol
              type
              _id
              cmcId
              containingPoolPairs {
                asset {
                  _id
                  cmcId
                  symbol
                  type
                }
                factor
              }
            }
            amount
            buyCoinPriceInFiat
            sellCoinPriceInFiat
            gainInFiat
            holdDays
          }
        }
      }

      succ {
        _id
        amounts {
          _id
          amount
          feeAmount
          asset {
            symbol
            type
            _id
            cmcId
            containingPoolPairs {
              asset {
                _id
                cmcId
                symbol
                type
              }
              factor
            }
          }
        }
        wallet {
          _id
          name
          exchange
        }
        meta {
          gains {
            asset {
              symbol
              type
              _id
              cmcId
              containingPoolPairs {
                asset {
                  _id
                  cmcId
                  symbol
                  type
                }
                factor
              }
            }
            amount
            buyCoinPriceInFiat
            sellCoinPriceInFiat
            gainInFiat
            holdDays
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query transactionsById($_ids: [ObjectId]) {
    transactionsById(_ids: $_ids) {
      ...DetailsTransaction
    }
  }
  ${DETAILS_TRANSACTION_FRAGMENT}
`;

export const HAS_PAID_WALLET_TRANSACTIONS = gql`
  query hasPaidWalletTransactions($wallet: ObjectId!) {
    hasPaidWalletTransactions(wallet: $wallet)
  }
`;

export const GET_UNPAID_WALLET_YEARS = gql`
  query unpaidYears($wallet: ObjectId!) {
    unpaidYears(wallet: $wallet)
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($transaction: TransactionUpdate!) {
    updateTransaction(transaction: $transaction) {
      ...ListTransaction
    }
  }
  ${LIST_TRANSACTION_FRAGMENT}
`;

const DETAILED_ASSET_BALANCES_FRAGMENT = gql`
  fragment DetailedAssetBalance on AssetBalance {
    asset {
      _id
      symbol
      type
      cmcId
      containingPoolPairs {
        asset {
          _id
          cmcId
          symbol
          type
        }
        factor
      }
    }
    taxFreeAt {
      date
      coinAmount
      unrealizedProfit
    }
    totalUnrealizedProfits
    displayAmount
    coinPrice
    coinAmount
    reportedCoinAmount
    debtCoinAmount
    percent
  }
`;

const FULL_WALLET_STATS_FRAGMENT = gql`
  fragment FullWalletStats on WalletStats {
    totalTransactions
    totalUnrealizedProfits
    historicalBalances
    historicalRewards
    assetBalances {
      ...DetailedAssetBalance
      subBalances {
        ...DetailedAssetBalance
      }
    }
  }
  ${DETAILED_ASSET_BALANCES_FRAGMENT}
`;

export const FULL_WALLET_FRAGMENT = gql`
  fragment FullWallet on Wallet {
    _id
    name
    exchange
    parentWallet {
      _id
      name
    }
    subWallets {
      _id
      name
      exchange
      stats {
        ...FullWalletStats
      }
      parentWallet {
        _id
        name
      }
      importedStatements {
        _id
        state
        parser
        filename
        totalTransactions
        firstTransactionAt
        lastTransactionAt
      }
    }
    stats {
      ...FullWalletStats
    }
    api {
      key
      lastSyncedAt
      state
      addresses
    }
    importedStatements {
      _id
      state
      parser
      filename
      totalTransactions
      firstTransactionAt
      lastTransactionAt
    }
  }
  ${FULL_WALLET_STATS_FRAGMENT}
`;

export const GET_WALLET = gql`
  query wallet($_id: ObjectId!) {
    wallet(_id: $_id) {
      ...FullWallet
    }
  }
  ${FULL_WALLET_FRAGMENT}
`;

export const GET_WALLETS_LITE = gql`
  query wallets {
    wallets(includeSubWallets: true) {
      _id
      name
      exchange
      api {
        key
        state
      }
      parentWallet {
        _id
        name
      }
    }
  }
`;

export const SYNC_API = gql`
  mutation syncApi($walletId: ObjectId) {
    syncAPI(walletId: $walletId)
  }
`;

export const GET_USER = gql`
  query getUser {
    user {
      _id
      isAdmin
      taxAdvising
      isDemo
      chatToken
      calculating
      email {
        current
        isVerified
      }
      address {
        firstname
      }

      isAffiliated
      affiliate {
        id
        earned
        liquidated
        recruitedUsers
        revenueShare
        discountShare
        discount
      }

      stats {
        transactionsWithWarnings
        totalTransactions
        totalUnrealizedProfits
        totalDisplayAmount
        historicalBalances
        historicalDebts
        historicalRewards
        assetBalances {
          ...DetailedAssetBalance
          subBalances {
            ...DetailedAssetBalance
          }
        }
      }

      displayAsset {
        _id
        symbol
        cmcId
      }

      locale
      taxRegion
    }
  }
  ${DETAILED_ASSET_BALANCES_FRAGMENT}
`;

export const GET_TAX_YEARS = gql`
  query taxYears {
    taxYears {
      _id
      year
      payment {
        paid
        at
        txLimit
        product
      }
      lastUpdated
      generating
      settings {
        introduction
        disclaimer
        userBalances
        walletBalances
        tradingVolume
        disposals
      }
      stats {
        txAmount
        warnings
        gainsInFiat {
          label
          recommendedTaxType
          gain
        }
        transactionTypes {
          type
          amount
        }
      }
    }
  }
`;

export const GET_ASSET_PAGE = gql`
  query assets($filters: [AssetFilters]) {
    assets(filters: $filters) {
      _id
      cmcId
      symbol
      name
      type
      containingPoolPairs {
        asset {
          _id
          cmcId
          symbol
          type
        }
        factor
      }
    }
  }
`;
